import Image from 'next/image';
import { ReactNode } from 'react';

import AuthTestimonials, { SlideProps } from 'components/common/elements/AuthTestimonials';
import { useTranslation } from 'lib/hooks/useTranslation';
import SignupImage from 'public/images/signup-illustration.png';
import { Container } from '../Container';
import style from './AuthLayout.module.scss';

interface Props {
    contentRight: ReactNode;
    formWidth?: string;
    showCardNotice?: boolean;
}

export const AuthLayout = ({ contentRight, formWidth, showCardNotice = false }: Props) => {
    const t = useTranslation();

    const dummySlides: SlideProps[] = [
        {
            title: 'THE WORLD’S FIRST PRIVACY COPILOT',
            subtitle: 'AI-driven insights for ultimate privacy control.',
            mainContent:
                'Ubiscore revolutionizes data protection by scanning the internet for privacy risks related to your company. In just minutes, it generates a Privacy Score from 100-900, benchmarking you against industry standards—no manual input required.',
        },
        {
            title: 'ELEVATE YOUR PRIVACY REPUTATION',
            subtitle: 'Trust is your most valuable asset.',
            mainContent:
                'With over 90% of online users concerned about data privacy, safeguarding customer information isn’t just a responsibility—it’s a strategic advantage. Privacy Copilot helps you build trust by proactively managing privacy risks.',
        },
        {
            title: 'AI-POWERED PRIVACY INTELLIGENCE',
            subtitle: 'Real-time insights, real-world protection.',
            mainContent:
                'Ubiscore provides an intuitive dashboard that uncovers privacy vulnerabilities, identifies compliance gaps, and delivers actionable recommendations. Know exactly where sensitive data is exposed and how to mitigate risks effectively.',
        },
    ];

    return (
        <Container>
            <div className={style.authLayout}>
                <div className={style.leftContainer}>
                    <div className={style.authTopDecoration}>
                        <Image src="/images/auth-top-decoration.svg" alt="auth top decoration" width={125} height={128} />
                    </div>
                    <div className={style.authTopDecoration2}>
                        <Image src="/images/auth-top-decoration-2.svg" alt="auth top decoration" width={78} height={78} />
                    </div>
                    <div className={style.sliderSlot}>
                        <AuthTestimonials slides={dummySlides} />
                    </div>
                    <div className={style.leftContainerContent}>
                        <Image src={SignupImage} alt="signup illustration" />
                    </div>
                </div>
                <div className={style.rightContainer}>
                    <div className={style.rightInner}>
                        <div className={style.rightInnerHeader}>
                            <Image width={153} height={32} src="/images/ubi-logo-form.png" alt="ubiscore logo" />
                            <p className={style.headerNotice}>{showCardNotice ? t.signup.tip : ''}</p>
                        </div>
                        <div className={style.form} style={{ width: formWidth }}>
                            {contentRight}
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
};
